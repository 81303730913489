<template>
  <div class="page-content-stock">
    <div class="header">
      <h3>Isolation</h3>
    </div>
    <hr />
    <div class="content-isolation">
      <div v-if="initLoading" class="init-loading three-dots-loading">
        Chargement en cours
      </div>
      <div v-else class="content-tab-isolation">
        <div>
          <b-table-simple
            small
            show-empty
            id="my-table2"
            :items="getStocksPlanifier"
            :sticky-header="true"
            empty-text="Il n'y a aucun enregistrement à afficher"
            empty-filtered-text="Aucun enregistrement ne correspond à votre demande"
          >
            <b-thead>
              <b-tr class="header-tr">
                <b-th>Semaine </b-th>
                <b-th>Plancher Bas </b-th>
                <b-th>Déroulé</b-th>
                <b-th>Soufflé</b-th>
                <b-th>Sous-Rampant</b-th>
                <b-th>ITE</b-th>
                <b-th>ITI</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <!-- TABLE TRAVAUX PLANIFIER -->
              <tr
                v-for="(item, index) in getStocksPlanifier.travaux_planifies"
                :key="index"
                :class="{ timeline: getStockProcessing }"
              >
                <td>{{ item.debut_semaine }} - {{ item.fin_semaine }}</td>
                <td>
                  {{
                    item.quantites_isolations_semaine &&
                    item.quantites_isolations_semaine.quantite_plancher_bas
                      ? item.quantites_isolations_semaine
                          .quantite_plancher_bas + ' m²'
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    item.quantites_isolations_semaine &&
                    item.quantites_isolations_semaine.quantite_deroule
                      ? item.quantites_isolations_semaine.quantite_deroule +
                        ' m²'
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    item.quantites_isolations_semaine &&
                    item.quantites_isolations_semaine.quantite_souffle
                      ? item.quantites_isolations_semaine.quantite_souffle +
                        ' m²'
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    item.quantites_isolations_semaine &&
                    item.quantites_isolations_semaine.quantite_sous_rampant
                      ? item.quantites_isolations_semaine
                          .quantite_sous_rampant + ' m²'
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    item.quantites_isolations_semaine &&
                    item.quantites_isolations_semaine.quantite_ite
                      ? item.quantites_isolations_semaine.quantite_ite + ' m²'
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    item.quantites_isolations_semaine &&
                    item.quantites_isolations_semaine.quantite_iti
                      ? item.quantites_isolations_semaine.quantite_iti + ' m²'
                      : '-'
                  }}
                </td>
              </tr>
              <!-- TOTAL -->
              <tr
                v-if="getStocksPlanifier && getStocksPlanifier.total"
                :class="{ timeline: getStockProcessing }"
              >
                <td class="bold">Total</td>
                <td>
                  {{
                    getStocksPlanifier.total &&
                    getStocksPlanifier.total.quantite_plancher_bas
                      ? getStocksPlanifier.total.quantite_plancher_bas + ' m²'
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    getStocksPlanifier.total &&
                    getStocksPlanifier.total.quantite_deroule
                      ? getStocksPlanifier.total.quantite_deroule + ' m²'
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    getStocksPlanifier.total &&
                    getStocksPlanifier.total.quantite_souffle
                      ? getStocksPlanifier.total.quantite_souffle + ' m²'
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    getStocksPlanifier.total &&
                    getStocksPlanifier.total.quantite_sous_rampant
                      ? getStocksPlanifier.total.quantite_sous_rampant + ' m²'
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    getStocksPlanifier.total &&
                    getStocksPlanifier.total.quantite_ite
                      ? getStocksPlanifier.total.quantite_ite + ' m²'
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    getStocksPlanifier.total &&
                    getStocksPlanifier.total.quantite_iti
                      ? getStocksPlanifier.total.quantite_iti + ' m²'
                      : '-'
                  }}
                </td>
              </tr>
              <!-- TABLE TRAVAUX A PLANIFIER -->
              <tr
                v-if="
                  getStocksAPlanifier &&
                    getStocksAPlanifier.travaux_a_planifier &&
                    getStocksAPlanifier.travaux_a_planifier
                      .quantites_isolations_a_planifier
                "
                class="top-border"
                :class="{ timeline: getStockAPlanifierProcessing }"
              >
                <td class="bold">Total à planifier</td>
                <td>
                  {{
                    getStocksAPlanifier.travaux_a_planifier &&
                    getStocksAPlanifier.travaux_a_planifier
                      .quantites_isolations_a_planifier.quantite_plancher_bas
                      ? getStocksAPlanifier.travaux_a_planifier
                          .quantites_isolations_a_planifier
                          .quantite_plancher_bas + ' m²'
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    getStocksAPlanifier.travaux_a_planifier &&
                    getStocksAPlanifier.travaux_a_planifier
                      .quantites_isolations_a_planifier.quantite_deroule
                      ? getStocksAPlanifier.travaux_a_planifier
                          .quantites_isolations_a_planifier.quantite_deroule +
                        ' m²'
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    getStocksAPlanifier.travaux_a_planifier &&
                    getStocksAPlanifier.travaux_a_planifier
                      .quantites_isolations_a_planifier.quantite_souffle
                      ? getStocksAPlanifier.travaux_a_planifier
                          .quantites_isolations_a_planifier.quantite_souffle +
                        ' m²'
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    getStocksAPlanifier.travaux_a_planifier &&
                    getStocksAPlanifier.travaux_a_planifier
                      .quantites_isolations_a_planifier.quantite_sous_rampant
                      ? getStocksAPlanifier.travaux_a_planifier
                          .quantites_isolations_a_planifier
                          .quantite_sous_rampant + ' m²'
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    getStocksAPlanifier.travaux_a_planifier &&
                    getStocksAPlanifier.travaux_a_planifier
                      .quantites_isolations_a_planifier.quantite_ite
                      ? getStocksAPlanifier.travaux_a_planifier
                          .quantites_isolations_a_planifier.quantite_ite + ' m²'
                      : '-'
                  }}
                </td>
                <td>
                  {{
                    getStocksAPlanifier.travaux_a_planifier &&
                    getStocksAPlanifier.travaux_a_planifier
                      .quantites_isolations_a_planifier.quantite_iti
                      ? getStocksAPlanifier.travaux_a_planifier
                          .quantites_isolations_a_planifier.quantite_iti + ' m²'
                      : '-'
                  }}
                </td>
              </tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'PacBallon',
  data() {
    return {
      initLoading: true
    }
  },
  methods: {
    ...mapActions(['fetchStocksPlannifier', 'fetchStocksAplannifier'])
  },
  computed: {
    ...mapGetters([
      'getStockErrorStocks',
      'getStockProcessing',
      'getStockAPlanifierProcessing',
      'getStocksPlanifier',
      'getStocksAPlanifier'
    ])
  },
  async mounted() {
    await this.fetchStocksPlannifier('isolation')
    this.fetchStocksAplannifier('isolation')
    this.initLoading = false
  }
}
</script>
<style lang="scss">
.content-tab-isolation {
  table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    border: 0;
    border-top: 9px solid #dee2e6;
    border-bottom: 1px solid #b9babb;
    margin-top: -8px;
    font-size: 13px;
    text-align: center;

    tr {
      &:hover {
        th {
          background: #dee2e6;
        }
      }
    }
  }
  .table.b-table.table-sm > thead > tr > [aria-sort].b-table-sort-icon-left,
  .table.b-table.table-sm > tfoot > tr > [aria-sort].b-table-sort-icon-left {
    background-position: left calc(0.3rem / 2) center;
    padding-left: calc(1.3rem + 0.65em);
  }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 1px solid #dee2e6;
  }
  .table-sm th,
  .table-sm td {
    // padding: 2px;
    border: 1px solid #dee2e6;
    vertical-align: middle;
  }
  .b-table-sticky-header {
    overflow-y: hidden;
    // max-height: 530px;
  }
  .table.b-table > thead > tr > .table-b-table-default,
  .table.b-table > tbody > tr > .table-b-table-default,
  .table.b-table > tfoot > tr > .table-b-table-default {
    font-size: 12px;
    color: #212529;
    background-color: #f8f8f8;
  }
  .b-table-sticky-header > .table.b-table > thead > tr > th {
    position: -webkit-sticky;
    position: sticky;
    top: -4px;
    z-index: 2;
    vertical-align: middle;
  }
}
</style>
<style scoped lang="scss">
.page-content-stock {
  margin: 60px;
  padding: 15px;
  min-height: 80%;
  background-color: #f8f8f8;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .content-isolation {
    .content-tab-isolation {
      .bold {
        font-weight: bold;
      }
      .top-border {
        border-top: 2px solid #dee2e6;
      }
      .timeline {
        width: 100%;
        height: 100%;
        color: transparent;
        animation-duration: 1.8s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background: transparent;
        background: linear-gradient(
          to right,
          #f6f6f6 0%,
          #dbdbdb 25%,
          #f6f6f6 50%
        );
        background-size: 1000px 640px;
        &.width {
          width: 220px;
          height: 250%;
        }
        &.height {
          height: 77px;
          width: 192px;
        }
      }

      @keyframes placeHolderShimmer {
        0% {
          background-position: -468px 0;
        }
        100% {
          background-position: 468px 0;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .page-content-stock {
    margin: 11px;
    padding: 15px;
    min-height: 96%;
    background-color: #f8f8f8;
  }
}
</style>
